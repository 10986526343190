body {
  background-color: #ffcd4e;
  height: auto;
}

.home-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
}

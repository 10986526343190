.body-voteCouncil {
  background-color: #ffcd4e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cardVoteCouncil {
  display: flex;
  background-color: white;
  width: 90%;
  height: 90%;
  border-radius: 45px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.auto-grid {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );

}

/* flex justify-center align-middle bg-white  w-[90%] h-[100%] p-[5%] rounded-3xl */

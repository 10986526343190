body {
  background-color: #ffcd4e;
}

.body-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
}

.container {
  display: flex;
  justify-content: center;
  height: 82%;
  width: 1200px;
}

.card {
  display: flex;
  width: 100%;
  height: 610px;
  border-radius: 50px;
  justify-content: center;
}

.cardImg {
  flex-basis: 60%;
  height: 610px;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 50px;
}

.imgLogin1 {
  width: 95%;
  height: 600px;
  border-radius: 40px;
  margin: auto auto;
}

.inputForm {
  width: 100%;
  height: 95%;
  border-radius: 25px;
  display: flex;
  align-items: center;
}

.input_contain {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.input_form {
  width: 45%;
}

.title {
  text-align: center;
  line-height: 150px;
  font-size: 3rem;
  color: #6b91fc;
  font-weight: bold;
  font-family: Comic Sans, Comic Sans MS, cursive;
}

input[type="text"] {
  font-size: 1rem;
  padding: 10px;
  width: 100%;
  border-width: 3px;
  border-color: #6b91fc;
  border-style: solid;
  border-radius: 20px;
  outline: transparent;
  margin-top: 5%;
  text-align: center;
}


.button_submit {
  font-size: 0.9rem;
  color: white;
  padding: 10px;
  width: 25%;
  border-radius: 20px;
  background-color: #6b91fc;
  border-width: 3px;
  border-color: #6b91fc;
  margin-top: 8%;
  margin-bottom: 15%;
}

.button_submit:hover {
  background-color: #ffcd4e;
  border-color: #ffcd4e;
}

@media screen and (max-width: 1024px) {
  .cardImg {
    display: none;
    flex-basis: 0%;
  }

  .cardForm {
    flex-basis: 90%;
    height: 850px;
    margin-right: 50px;
  }

  .title {
    margin-top: 6rem;
    text-align: center;
    line-height: 250px;
    font-size: 5rem;
  }

  input[type="text"] {
    font-size: 2rem;
    width: 100%;
    margin-top: 5%;
  }

  .button_submit {
    font-size: 1.8rem;
    margin-top: 3%;
  }
}

@media screen and (max-width: 478px) {
  .cardImg {
    display: none;
    flex-basis: 0%;

  }

  .cardForm {
    flex-basis: 90%;
    height: 600px;
    margin-right: 20px;
  }
  .title {
    margin-top: 0.1rem;
    text-align: center;
    line-height: 150px;
    font-size: 3.8rem;
  }

  input[type="text"] {
    font-size: 1.1rem;
    width: 100%;
    margin-top: 5%;
  }

  .button_submit {
    font-size: 1.1rem;
    margin-top: 3%;
    width: 35%;
  }
  .input_form {
    width: 70%;
  }
}
